import React from 'react'

import SEO from '../../components/seo'
import Section from '../../components/Common/Section'
import Container from '../../components/Common/Container'
import Typography from '../../components/Common/Typography'
import Link from '../../components/Common/Link'
import { Button } from '../../components/Common/Button'
import ContactForm from '../../components/contact-form'
import { useTranslations } from '../../hooks/use-translations'
import WhatsappBtnIcon from '../../svg/whatsapp-btn-icon'
import { toLink } from '../../utils'
import { whatsappLinkUs } from '../../data'

import * as s from './contact.module.scss'

const Contact: React.FC = () => {
  const { t } = useTranslations()
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const email = t('info_email')
  const phone = t('Phone')
  const mobPhone = t('mob_phone')

  return (
    <>
      <SEO
        title={t('seo_title_contact')}
        description="Need to get in touch with SIGNAX or have questions? We’re happy to help. Give us a call or email us today."
      />
      <Section className={s.section}>
        <Container>
          <div className={s.columns}>
            <div className={s.column}>
              <Typography
                variant="h1"
                color="blue"
                size={40}
                className={s.marginBottom}
              >
                {t('title_contact')}
              </Typography>
              <Typography
                variant="h2"
                size={18}
                color="blue"
                className={s.text}
              >
                {t('contact_phone')}
              </Typography>
              <div>
                <Typography variant="body2" size={18} className={s.phone}>
                  {t('contact_tel')}
                </Typography>
                <Link href={phone} typeLink="tel" color="dark">
                  {phone}
                </Link>
              </div>
              <div>
                <Typography variant="body2" size={18} className={s.phone}>
                  {t('contact_mob')}
                </Typography>
                <Link href={mobPhone} typeLink="tel" color="dark">
                  {mobPhone}
                </Link>
              </div>
              <Typography
                variant="h2"
                size={18}
                color="blue"
                className={s.text}
              >
                {t('contact_email')}
              </Typography>
              <Link href={email} typeLink="email" color="dark">
                {email}
              </Link>
              <Typography
                variant="h2"
                size={18}
                color="blue"
                className={s.text}
              >
                {t('address_title')}
              </Typography>
              <Typography variant="body1" size={18} className={s.marginBottom}>
                {t('_address')}
              </Typography>
            </div>
            <div className={s.column}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d903.4485810315041!2d55.13966971274514!3d25.074959326539823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6caaf8181d8b%3A0xdfa5773a1c64bbc5!2sMarina%20Plaza%20-%20Dubai%20Marina%20-%20Emaar!5e0!3m2!1sen!2sca!4v1676468258809!5m2!1sen!2sca"
                title="Map"
                className={s.map}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
          <div className={s.btns}>
            <Button onClick={() => setIsOpenModal(true)}>
              {t('Request a call')}
            </Button>
            <Button onClick={() => toLink(whatsappLinkUs)}>
              <WhatsappBtnIcon style={{ marginRight: 12 }} />
              {t('whatsapp_chat')}
            </Button>
          </div>
        </Container>
      </Section>
      {isOpenModal && (
        <ContactForm
          onClose={() => setIsOpenModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default Contact
